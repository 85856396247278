<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('TechnicalSupportHelpTreeCreate')"
    @edit="(id) => onEdit('TechnicalSupportHelpTreeEdit', id)"
  >
    <template #datatable-top-panel>
      <sm-button class="validate-tree-button" @click="onValidateHelpTree">
        Проверить корректность
      </sm-button>
    </template>
  </sm-editable-list>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'TechnicalSupportHelpTree',

  components: {
    SmEditableList,
    SmButton,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TechnicalSupportHelpTree',
      tableCaption: 'Дерево решений по тех. поддержке',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Дерево решений по тех. поддержке',
        },
      ],
      tableHeaders: [
        { text: 'Текст варианта выбора', alias: 'name', order: 'name' },
        {
          text: 'Родительский узел для этой записи',
          alias: 'parentName',
          order: 'parentName',
        },
        {
          text: 'Является ли элемент решением',
          alias: 'isConclusion',
          order: 'isConclusion',
        },
        {
          text: 'Текст решения, если элемент является решением',
          alias: 'conclusion',
          order: 'conclusion',
        },
        { alias: 'actions' },
      ],
    };
  },

  computed: {
    ...mapState({
      technicalSupportHelpTreeNodes: (state) =>
        state.common.technicalSupportHelpTreeNodes,
    }),
  },

  created() {
    this.getCommonList({ name: 'TechnicalSupportHelpTreeNodes' });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      validateHelpTree: 'technicalSupportHelpTree/validateHelpTree',
    }),

    onValidateHelpTree() {
      this.validateHelpTree().then(({ isSucceed }) => {
        if (isSucceed) {
          this.$notify({
            header: 'Проверка завершена',
            text: 'Дерево решений по тех. поддержке корректно',
            type: 'success',
            timer: 5000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.validate-tree-button {
  width: auto;
  margin-left: 15px;
}
</style>
